import React from "react";

function iconIdentity(veggieForm) {
    return ("../../media/"+veggieForm+".svg");
}

function toggleAllergens(id) {
    let allergenId = "popup-" + id;
    document.getElementById(allergenId).classList.toggle("show");
    console.log(allergenId);
}

function MenuItem(props) {
    return (
        <div className="menu-item-container col-lg-6">
            <div className="col-10 popup-container" id={`popup-${props.id}`}>
                <span className="allergens-explained">
                    {props.allergensExplained}
                </span>
                <span className="closing-x" onClick={() => toggleAllergens(props.id)}>X</span>
            </div>
            <div className="row food-item-title-row">
                <div className="col-10 food-item-title-column">
                    <div className="food-item-title-wrapper">
                        <h4 className="food-item-title">
                            {props.veggie !== '' ? 
                                <img className="veggie-icon" src={iconIdentity(props.veggie)} alt="" />
                                :null
                            }
                            {props.number !== '' ?
                                <span className="food-item-number">{props.number + " "}</span>
                                :null
                            }                            
                            <span className="food-item-name">{props.name}</span>
                            {props.allergens !== '' ?
                                <sup onClick={() => toggleAllergens(props.id)} className="food-item-allergens">
                                    {" " + props.allergens}
                                </sup>
                                :null                            
                            }
                        </h4>
                    </div>
                </div>
                <div className="col-2 food-item-title-column">
                    <span className="food-item-price">{props.price}</span>
                </div>
            </div>
            <p className="food-item-short-description">{props.shortDescription}</p>
        </div>
    ) 
}

export default MenuItem;