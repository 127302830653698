import React from "react";
import Navigation from "./Navigation";
import Home from "./Home";
import Speisekarte from "./Speisekarte";
import Footer from "./Footer";
import Datenschutz from "./Datenschutz";
import Impressum from "./Impressum";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import Popup from "./Popup";

//yarn start
//npm run build
//cd build
//netlify deploy --prod

function App() {
  // uncomment below to activate popup
  // const [isPopupVisible, setPopupVisible] = useState(false);
  // useEffect(() => {
  //   setPopupVisible(true);
  // }, []);

  // const handleClosePopup = () => {
  //   setPopupVisible(false);
  // };
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* uncomment below to activate popup */}
        {/* {isPopupVisible && <Popup onClose={handleClosePopup} />} */}
        <Navigation />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/speisekarte" exact element={<Speisekarte />} />
          <Route path="/impressum" exact element={<Impressum />} />
          <Route path="/datenschutz" exact element={<Datenschutz />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
