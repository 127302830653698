import React from 'react';

function DividerFoodCategoryBanner() {
    return (
        <div className="col-12 divider-col">
            <hr className="banner-divider" />
        </div> 
    )
}

export default DividerFoodCategoryBanner;