import { v4 as uuid4 } from "uuid";
// this is the actual place for all the food items

const foodItems = [
  {
    // category object Vorspeisen
    id: uuid4(),
    name: "Vorspeisen",
    imgName: "vorspeisen",
    nameColored: "Vor",
    nameWhite: "speisen",
    subCategories: [
      {
        // subCategory object Vorspeisen
        id: uuid4(),
        name: "Vorspeisen",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Tagessuppe",
            shortDescription: "",
            price: "6,50€",
            allergens: "",
            allergensExplained: "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Knoblauchbrot",
            shortDescription:
              "4 Scheiben | nach griechischer Art: mit Tomaten, Knoblauch, Feta (6,00€) | Mozzarella Austernpilze: mit Trüffelöl (6,50€)",
            price: "5,00€",
            allergens: "WZ, M",
            allergensExplained: "Weizen (sowie Dinkel und Kamut), Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Feta",
            shortDescription: "Schafskäse mit Oliven, Peperoni und Olivenöl",
            price: "7,00€",
            allergens: "6",
            allergensExplained: "geschwärzt",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Octopussalat",
            shortDescription: "mit Paprika, Zwiebeln, Karotten, Öl und Essig",
            price: "10,00€",
            allergens: "W",
            allergensExplained: "Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Chtipiti",
            shortDescription: "würzige Käsecreme",
            price: "6,00€",
            allergens: "M, 2",
            allergensExplained: "Milch, mit Konservierungsstoff",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Tsatsiki",
            shortDescription:
              "cremig gerührter Joghurt mit Gurken, Knoblauch und Olivenöl",
            price: "6,00€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Tsatsiki Spezial",
            shortDescription:
              "cremig gerührter Joghurt mit Gurken rote und grüne gegrillte Spitzpaprika, Olivenöl und Knoblauch",
            price: "6,50€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Dolmadakia",
            shortDescription: "6 Weinblätter, gefüllt mit Reis dazu Tsatsiki",
            price: "6,50€",
            allergens: "M, So, 2",
            allergensExplained: "Milch, Sojabohnen, mit Konservierungsstoff",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Auberginenröllchen",
            shortDescription:
              "Auberginen gefüllt mit Schafskäse und Basilikum in Tomatensoße",
            price: "8,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Feta Fournou Mediterran",
            shortDescription:
              "Schafskäse, Tomate, Paprika und Knoblauch aus dem Backofen",
            price: "9,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Meze",
            shortDescription:
              "gemischte warme und kalte Vorspeiseplatte. Augeberginen, Zucchini, Tsatsiki, Chtipiti, Peperoni, Saganaki, Zucchinibällchen",
            price: "13,00€",
            allergens: "M, Wz, EI, 2",
            allergensExplained:
              "Milch, Weizen (sowie Dinkel und Kamut), Eier, mit Konservierungsstoff",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Pepperoni",
            shortDescription: "gegrillt, dazu Tsatsiki",
            price: "7,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Midia Saganaki",
            shortDescription: "Muschelfleisch mit Schafskäse und Knoblauch",
            price: "8,50€",
            allergens: "M, K, W",
            allergensExplained: "Milch, Krebstiere, Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Florinis",
            shortDescription:
              "gegrillte rote Spitzpaprika, gefüllt mit Schafskäse. Tomatenstücke, etwas Knoblauch und Frühlingszwiebeln. Mit Käse überbacken.",
            price: "9,80€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Saganaki",
            shortDescription:
              "frittierter Schafskäse in Backteighülle mit Salatbouquet",
            price: "7,50€",
            allergens: "M, Wz, EI",
            allergensExplained: "Milch, Weizen (sowie Dinkel und Kamut), Eier",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Auberginen & Zucchini",
            shortDescription:
              "frittiert in einer Backteighülle serviert mit Tsatsiki",
            price: "7,50€",
            allergens: "M, Wz, EI",
            allergensExplained: "Milch, Weizen (sowie Dinkel und Kamut), Eier",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kolokythokeftedes",
            shortDescription: "gebratene Zucchini-Gemüsebällchen dazu Tsatsiki",
            price: "7,50€",
            allergens: "M, Wz, EI",
            allergensExplained: "Milch, Weizen (sowie Dinkel und Kamut), Eier",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Austernpilze",
            shortDescription:
              "auf Rucolabeet mit Knoblauch und Olivenöl-Zitronendressing",
            price: "11,00€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Tiropitakia",
            shortDescription:
              "3 Teigtaschen gefüllt mit Schafskäse mit Honig und Sesam garniert",
            price: "8,00€",
            allergens: "Wz, M, Ses",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Sesamsamen",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Chaloumi",
            shortDescription: "gegrillt auf Rucolabeet mit Grillgemüse",
            price: "10,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gigantes",
            shortDescription:
              "weiße Riesenbohnen in Tomatensoße mit Fetaflocken",
            price: "7,00€",
            allergens: "Sel, M, 3",
            allergensExplained: "Sellerie, Milch, mit Antioxidationsmittel",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Garides Saganaki",
            shortDescription:
              "5 Garnelen in Tomatensoße mit Schafskäse Und Spitzpaprika aus dem Backofen",
            price: "13,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
        ],
      },
    ],
    // end of category object Vorspeisen
  },
  {
    // category object Beilagen und Salate
    id: uuid4(),
    name: "Beilagen und Salate",
    imgName: "beilagen-und-salate",
    nameColored: "Beilagen & ",
    nameWhite: "Salate",
    subCategories: [
      {
        // subCategory object Salate
        id: uuid4(),
        name: "Salate",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Krautsalat",
            shortDescription: "mit Öl und Essig",
            price: "3,70€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Choriatiki",
            shortDescription:
              "(griech. Bauernsalat) Tomaten, Gurken, Paprika, Zwiebeln, Oliven, Peperoni, Schafskäse, Olivenöl und Oregano",
            price: "9,50€",
            allergens: "M, 6",
            allergensExplained: "Milch, geschwärzt",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gemischter Salat",
            shortDescription:
              "mit Gurken, Tomaten, Paprika, Karotten, Zwiebeln und Hausdressing wahlweise mit: Hähnchenbrustfilet oder Gyros (14,00€) | gebackene Kalamaris (15,00€) | 4 gegrillten Garnelen (18,00€)",
            price: "9,00€",
            allergens: "Sen, Sel, EI, M, 1",
            allergensExplained: "Senf, Sellerie, Eier, Milch, mit Farbstoff",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Choriatiki Mikri",
            shortDescription: "(kleiner griech. Bauernsalat)",
            price: "7,00€",
            allergens: "M, 6",
            allergensExplained: "Milch, geschwärzt",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Pantzari",
            shortDescription:
              "Rote Beete Salat mit Orangenstückchen, Zwiebeln, Olivenöl und Balsamico",
            price: "7,50€",
            allergens: "Su, Wz, 2",
            allergensExplained:
              "Schwefeldioxid und Sulphide, Weizen (sowie Dinkel und Kamut), mit Konservierungsstoff",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Florini-Salat",
            shortDescription:
              "gegrillte, rote Spitzpaprika mit Tomaten, Schafskäse, Oliven, Kräuterpesto, dazu 2 Scheiben Knoblauchbrot",
            price: "10,00€",
            allergens: "M, Wz, 6",
            allergensExplained:
              "Milch, Weizen (sowie Dinkel und Kamut), geschwärzt",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Rucolasalat Mediterran",
            shortDescription:
              "Rucola, grüner Salat, Austernpilze Parmesan, Walnüsse und Coctailtomaten mit Olivenöl-Zitronendressing",
            price: "11,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
        ],
      },
      {
        // subCategory object Beilagen
        id: uuid4(),
        name: "Beilagen",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Kleine Portion Tsatsiki",
            shortDescription: "",
            price: "4,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Reis",
            shortDescription: "",
            price: "3,50€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Pommes",
            shortDescription: "",
            price: "3,50€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Pitabrot",
            shortDescription: "griechisches Fladenbrot",
            price: "3,00€",
            allergens: "Wz, EI, 2",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Eier, mit Konservierungsstoff",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Rosmarinkartoffeln",
            shortDescription: "griech. Art",
            price: "6,00€",
            allergens: "4",
            allergensExplained: "mit Geschmacksverstärker",
            veggie: "vegetarisch",
          },
        ],
      },
    ],
    // end of category object Beilagen und Salate
  },
  {
    // category object Warme Gerichte
    id: uuid4(),
    name: "Warme Gerichte",
    imgName: "warme-gerichte",
    nameColored: "Warme ",
    nameWhite: "Gerichte",
    subCategories: [
      {
        // subCategory object Gemischte Platten
        id: uuid4(),
        name: "Gemischte Platten",
        subtitle:
          "Alle Hauptgerichte werden mit einem gemischten Salat serviert",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Gyros",
            shortDescription: "mit Reis und Tsatsiki",
            price: "15,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gyros und Kalamari",
            shortDescription: "(paniert) mit Reis und Tsatsiki",
            price: "17,00€",
            allergens: "Wz, M, W",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Suvlaki und Gyros",
            shortDescription: "mit Reis und Tsatsiki",
            price: "16,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Suvlaki, Bifteki, Gyros",
            shortDescription: "mit Reis und Tsatsiki",
            price: "17,00€",
            allergens: "M, EI",
            allergensExplained: "Milch, Eier",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Suvlaki, Steak, Gyros",
            shortDescription: "mit Reis und Tsatsiki",
            price: "17,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Suvlaki und Kalamari",
            shortDescription: "mit Reis und Tsatsiki",
            price: "17,00€",
            allergens: "M, W",
            allergensExplained: "Milch Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Lammkoteletts",
            shortDescription: "(2 Stück) Leber, Gyros mit Reis und Tsatsiki",
            price: "20,00€",
            allergens: "M, 4",
            allergensExplained: "Milch, mit Geschmacksverstärker",
            veggie: "",
          },
        ],
      },
      {
        // subCategory object Fleischgerichte vom Grill
        id: uuid4(),
        name: "Fleischgerichte vom Grill",
        subtitle: "Alle Hauptgerichte werden mit einem gemischten Salat serviert",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Suvlaki (2 Stück)",
            shortDescription: "mit Reis und Tsatsiki",
            price: "16,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Bifteki",
            shortDescription: "2 saftige Hacksteaks mit Reis und Tsatsiki",
            price: "16,00€",
            allergens: "M, EI",
            allergensExplained: "Milch, Eier",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Lendchenfilet",
            shortDescription: "gefüllt mit Schafskäse, dazu Rosmarinkartoffeln",
            price: "19,00€",
            allergens: "",
            allergensExplained: "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gemisto Bifteki",
            shortDescription:
              "Hacksteak gefüllt mit Schafskäse, Tomate und Peperoni mit Pommes",
            price: "18,00€",
            allergens: "M, EI",
            allergensExplained: "Milch, Eier",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Hähnchenbrustfilet",
            shortDescription: "mit Reis und Tsatsiki",
            price: "18,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Saftige Rückensteaks",
            shortDescription: "2 Stück mit Kräuterbutter und Pommes",
            price: "16,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Sikoti",
            shortDescription: "Rinderleber mit Röstzwiebeln, Reis und Tsatsiki",
            price: "16,00€",
            allergens: "Wz, M",
            allergensExplained: "Weizen (sowie Dinkel und Kamut), Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Choriatiko",
            shortDescription:
              "(Griechischer Bauernspieß) mit Pommes, Tsatiki und Knoblauchbrot",
            price: "18,00€",
            allergens: "Wz, M",
            allergensExplained: "Weizen (sowie Dinkel und Kamut), Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Rumpsteak",
            shortDescription:
              "mit Kräuterbutter, Rosmarinkartoffeln und Cherry Tomaten",
            price: "20,80€",
            allergens: "M, 4",
            allergensExplained: "Milch, mit Geschmacksverstärker",
            veggie: "",
          },
        ],
      },
      {
        // subCategory object Fischgerichte
        id: uuid4(),
        name: "Fischgerichte",
        subtitle: "Alle Hauptgerichte werden mit einem gemischten Salat serviert",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Kalamari",
            shortDescription: "(paniert) mit Reis und Tsatsiki",
            price: "17,00€",
            allergens: "Wz, M, W",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kalamari und Garnelen",
            shortDescription: "mit Reis und Tsatsiki",
            price: "18,50€",
            allergens: "Wz, M, W, K",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Weichtiere, Krebstiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kalamari und Garnelen",
            shortDescription: "gegrillt mit Reis und Tsatsiki",
            price: "21,50€",
            allergens: "M, K",
            allergensExplained: "Milch, Krebstiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kalamari",
            shortDescription:
              "gegrillt mit Kräuter-Pesto, Pinienkerne, Pfannengemüse und Reis",
            price: "20,00€",
            allergens: "M, W, EI, K",
            allergensExplained: "Milch, Weichtiere, Eier, Krebstiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kalamari, Garnelen, Gavros",
            shortDescription: "mit Reis und Tsatsiki",
            price: "19,50€",
            allergens: "Wz, M, W, K",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Weichtiere, Krebstiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kalamari Gemisto",
            shortDescription:
              "gegrillt, mit Schafskäse und Tomaten gefüllt, dazu Reis",
            price: "20,00€",
            allergens: "M, W",
            allergensExplained: "Milch, Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kalamari",
            shortDescription:
              "aus der Pfanne in Tomaten-Weißweinsoße (pikant) dazu Reis",
            price: "19,00€",
            allergens: "M, W, Su",
            allergensExplained:
              "Milch, Weichtiere, Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Lachsfilet",
            shortDescription: "gegrillt mit Pfannengemüse und Tsatsiki",
            price: "20,50€",
            allergens: "M, 4",
            allergensExplained: "Milch, mit Geschmacksverstärker",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Lachsfilet Genovese",
            shortDescription:
              "gegrillt mit Kräuter-Pesto, Pinienkerne, Pfannengemüse und Reis",
            price: "21,50€",
            allergens: "M, EI, Ka",
            allergensExplained: "Milch, Eier, Kaschunüsse",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gavros (Sardinen gebacken)",
            shortDescription: "mit Reis und Tsatsiki",
            price: "15,50€",
            allergens: "Wz, W",
            allergensExplained: "Weizen (sowie Dinkel und Kamut), Weichtiere",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Zanderfilet",
            shortDescription: "gebacken mit Reis und Tsatsiki",
            price: "18,00€",
            allergens: "M, W, 4",
            allergensExplained: "Milch, Weichtiere, mit Geschmacksverstärker",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Garnelen (8 Stück)",
            shortDescription: "gegrillt mit Reis und Tsatsiki",
            price: "22,50€",
            allergens: "M, K",
            allergensExplained: "Milch, Krebstiere",
            veggie: "",
          },
        ],
      },
      {
        // subCategory object Lammspezialitäten
        id: uuid4(),
        name: "Lammspezialitäten & Tiganaki",
        subtitle: "Alle Hauptgerichte Werden Mit Einem Gemischten Salat Serviert",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Lammhaxe",
            shortDescription: "mit Rosmarinkartoffeln",
            price: "18,50€",
            allergens: "4",
            allergensExplained: "mit Geschmacksverstärker",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kotopoulo a la Creme",
            shortDescription:
              "Hähnchenbrustfilet in einer Champignon-Lauch-Weißweinsahnesoße, dazu Reis",
            price: "18,50€",
            allergens: "M, Su",
            allergensExplained: "Milch, Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Lammkoteletts (5 Stück)",
            shortDescription: "mit Pommes, Reis und Tsatsiki",
            price: "20,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Lendchen a la Creme",
            shortDescription:
              "Lendchenfilet mit Champignons, Lauch, Paprika, Knoblauch in einer Weißweinsahnesoße, dazu Reis",
            price: "20,00€",
            allergens: "M, Su",
            allergensExplained: "Milch, Schwefeldioxid und Sulphide",
            veggie: "",
          },
        ],
      },
      {
        // subCategory object Kleine Gerichte
        id: uuid4(),
        name: "Kleine Gerichte",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Gyros",
            shortDescription: "mit Pommes und Tsatsiki",
            price: "11,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gyros",
            shortDescription: "in Metaxa-Soße mit Käse überbacken, dazu Pommes",
            price: "13,00€",
            allergens: "EI, Wz, Ge",
            allergensExplained: "Eier, Weizen (sowie Dinkel und Kamut), Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gyros",
            shortDescription: "mit Pommes und Kalamari",
            price: "12,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Gyrospfanne",
            shortDescription: "Gemüse-Sahne-Soße, dazu Reis",
            price: "12,50€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Schnitzel",
            shortDescription: "mit Pommes",
            price: "11,00€",
            allergens: "Wz, EI, M",
            allergensExplained: "Weizen (sowie Dinkel und Kamut), Eier, Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Schnitzel",
            shortDescription:
              "wahlweise mit Metaxa-Soße und Pommes oder Jägersoße mit Pommes",
            price: "13,00€",
            allergens: "Wz, EI, M, 1",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Eier, Milch, mit Farbstoff",
            veggie: "",
          },

          {
            id: uuid4(),
            number: "",
            name: "Suvlaki",
            shortDescription: "gegrillt mit Pommes und Tsatsiki",
            price: "11,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Hähnchenbrustfilet",
            shortDescription: "gegrillt mit Pommes und Tsatsiki",
            price: "13,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Sikoti",
            shortDescription: "Gegrillte Leber",
            price: "12,50€",
            allergens: "",
            allergensExplained: "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Saftige Rückensteaks",
            shortDescription: "mit Kräuerbutter und Pommes",
            price: "13,00€",
            allergens: "M",
            allergensExplained: "Milch",
            veggie: "",
          }
        ],
      },
      // end of subCategories array for the category object Warme Gerichte
    ],
    // end of category object Warme Gerichte
  },
  {
    // category object Etwas Süßes
    id: uuid4(),
    name: "Etwas Süßes",
    imgName: "etwas-suesses",
    nameColored: "Etwas ",
    nameWhite: "Süßes",
    subCategories: [
      {
        // subCategory object Etwas Süßes
        id: uuid4(),
        name: "Etwas Süßes",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Galaktoboureko",
            shortDescription:
              "Blätterteig, gefüllt mit einer Vanille-Grieß-Creme und mit Orangensirup verfeinert, dazu Vanilleeis",
            price: "6,50€",
            allergens: "Wz, M, EI, 1",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Eier, mit Farbstoff",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Schokoladenküchlein",
            shortDescription: "mit Vanilleeis",
            price: "6,50€",
            allergens: "Wz, M, EI, 1, So",
            allergensExplained:
              "Weizen (sowie Dinkel und Kamut), Milch, Eier, mit Farbstoff, Sojabohnen",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Jaourtomelo",
            shortDescription: "Joghurt mit Honig und gehackten Walnüssen",
            price: "4,80€",
            allergens: "",
            allergensExplained: "",
            veggie: "vegetarisch",
          },
          {
            id: uuid4(),
            number: "",
            name: "Vanilleeis",
            shortDescription:
              "(3 Kugeln) mit warmer Himbeersoße oder warmer Schokosoße",
            price: "4,80€",
            allergens: "M, So, 1",
            allergensExplained: "Milch, Sojabohnen, mit Farbstoff",
            veggie: "vegetarisch",
          },
        ],
      },
    ],
    // end of category object Etwas Süßes
  },
  {
    // category object Getränke
    id: uuid4(),
    name: "Getränke",
    imgName: "getraenke",
    nameColored: "Ge",
    nameWhite: "tränke",
    subCategories: [
      {
        // subCategory object Etwas Süßes
        id: uuid4(),
        name: "Alkoholfreie Getränke",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Coca Cola (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "1, 2, 3, 11",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Coca Cola Light (0,33l)",
            shortDescription:
              "",
            price: "3,60€",
            allergens: "1, 2, 3, 8, 11",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Mezzo Mix (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "1, 2, 3",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Sprite (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "2, 3",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Fanta (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "1, 2, 3",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Apfelschorle (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Orangenschorle (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Traubensaftschorle (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Maracujasaftschorle (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Apfelsaft (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Orangensaft (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Johannisbeer-Nektar (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Traubensaft (0,4l)",
            shortDescription:
              "Klein (0,2l): 3,20€",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Tafelwasser (0,4l)",
            shortDescription:
              "Klein (0,2l): 2,40€",
            price: "3,30€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Flasche Naturell (0,5l)",
            shortDescription:
              "",
            price: "4,30€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Flasche Spritzig (0,5l)",
            shortDescription:
              "",
            price: "4,30€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },

        ],
      },
      // end of subcategory Alkoholfreie Getränke
      {
        // subCategory object Warme und kalte Getränke
        id: uuid4(),
        name: "Warme und kalte Getränke",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Mokka",
            shortDescription:
              "",
            price: "3,40€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Tasse Tee",
            shortDescription:
              "verschiedene Sorten",
            price: "3,10€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Tasse Kaffee",
            shortDescription:
              "",
            price: "2,90€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Frapes",
            shortDescription:
              "",
            price: "4,00€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Frapes mit Eis",
            shortDescription:
              "Griechischer Eiskaffee",
            price: "5,00€",
            allergens: "M",
            allergensExplained:
              "Milch",
            veggie: "",
          },

        ],
      },
      // end of subCategory Warme und kalte Getränke
      {
        // subCategory object Biere
        id: uuid4(),
        name: "Biere",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Helles Bier vom Fass (0,5l)",
            shortDescription:
              "",
            price: "4,10€",
            allergens: "GE",
            allergensExplained:
              "Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Radler (0,5l)",
            shortDescription:
              "",
            price: "4,10€",
            allergens: "GE, 2, 3",
            allergensExplained:
              "Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Kellerbier Kitzmann vom Fass (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "GE",
            allergensExplained:
              "Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Pils Flasche (0,5l)",
            shortDescription:
              "",
            price: "4,10€",
            allergens: "GE",
            allergensExplained:
              "Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Hefeweizen vom Fass (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "WZ, GE",
            allergensExplained:
              "Weizen, Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Dunkles Weizen Flasche (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "WZ, GE",
            allergensExplained:
              "Weizen, Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Leichtes Weizen (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "WZ, GE",
            allergensExplained:
              "Weizen, Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Cola Weizen (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "WZ, GE, 1, 2, 3, 5, 11",
            allergensExplained:
              "Weizen, Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Russen (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "WZ, GE, 1, 2, 3, 5",
            allergensExplained:
              "Weizen, Gerste",
            veggie: "",
          },
        ],
      },
      // end of subCategory Biere
      {
        // subCategory object Alkoholfreie Biere
        id: uuid4(),
        name: "Alkoholfreie Biere",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Weizen (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "WZ, GE",
            allergensExplained:
              "Weizen, Gerste",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Bier (0,5l)",
            shortDescription:
              "",
            price: "4,20€",
            allergens: "GE",
            allergensExplained:
              "Gerste",
            veggie: "",
          },
        ],
      },
      // end of subCategory Alkoholfreie Biere
      {
        // subCategory object Aperitif
        id: uuid4(),
        name: "Aperitif",
        subtitle: "",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Prosecco (0,2l)",
            shortDescription:
              "",
            price: "4,00€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Aperol Spritz (3cl)",
            shortDescription:
              "",
            price: "6,50€",
            allergens: "SU, 1, 2, 12",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Ouzo auf Eis (2cl)",
            shortDescription:
              "",
            price: "3,60€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Ouzo Plomari (2cl)",
            shortDescription:
              "",
            price: "4,40€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Ouzo Plomari (200ml Flasche)",
            shortDescription:
              "auf Eis (Liebling der Griechen)",
            price: "13,00€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Ouzo (2cl)",
            shortDescription:
              "Liebling der Griechen",
            price: "2,80€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Metaxa (2cl)",
            shortDescription:
              "",
            price: "3,40€",
            allergens: "1",
            allergensExplained:
              "mit Farbstoff",
            veggie: "",
          },
        ],
      },
      // end of subCategory Aperitif
    ],
    // end of category object Getränke
  },
  {
    // category object Wein
    id: uuid4(),
    name: "Wein",
    imgName: "wein",
    nameColored: "We",
    nameWhite: "in",
    subCategories: [
      {
        // subCategory object Offene Griechische Tafelweine
        id: uuid4(),
        name: "Offene griechische Tafelweine",
        subtitle: "0,25l weiß",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Hauswein",
            shortDescription:
              "trocken",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Chardonnay",
            shortDescription:
              "trocken",
            price: "6,40€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Retsina",
            shortDescription:
              "geharzt",
            price: "4,90€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Retsina Malamatina",
            shortDescription:
              "Flasche, geharzt",
            price: "5,20€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Makedonikos",
            shortDescription:
              "halbtrocken",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Samos",
            shortDescription:
              "süß",
            price: "6,40€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
        ],
      },
      // end of subCategory Offene Griechische Tafelweine
      {
        // subCategory object Flaschenweine weiß
        id: uuid4(),
        name: "Flaschenweine",
        subtitle: "weiß",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Retsina Kechribari (0,5l)",
            shortDescription:
              "Retsina ist ein weißer, trockener Tafelwein aus Griechenland, der mit Harz versetzt wird.",
            price: "11,50€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Alpha Estate. g.g.A. Florina / Amynteon (0,7l)",
            shortDescription:
              "Rebsorte: Sauvignon Blanc. Anbaugebiet: Makedonien",
            price: "28,50€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
        ],
      },
      // end of subCategory Flaschenweine weiß
      {
        // subCategory object Offene Griechische Tafelweine
        id: uuid4(),
        name: "Offene griechische Tafelweine",
        subtitle: "0,25l rosé",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Retsina",
            shortDescription:
              "geharzt",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Makedonikos",
            shortDescription:
              "halbtrocken",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "St. Laura",
            shortDescription:
              "trocken",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
        ],
      },
      // end of subCategory Offene Griechische Tafelweine rose
      {
        // subCategory object Flaschenweine rose
        id: uuid4(),
        name: "Flaschenweine",
        subtitle: "rosé",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Biblia Chora (15,5%) (0,7l)",
            shortDescription:
              "Rebsorte: Syrah. Anbaugebiet: Makedonien Thrakien",
            price: "28,90€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
        ],
      },
      // end of subCategory Flaschenweine rose
      {
        // subCategory object Offene Griechische Tafelweine
        id: uuid4(),
        name: "Offene griechische Tafelweine",
        subtitle: "0,25l rot",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Hauswein",
            shortDescription:
              "trocken",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Cabernet Sauvignon",
            shortDescription:
              "trocken",
            price: "6,60€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Merlot",
            shortDescription:
              "trocken",
            price: "6,90€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Makedonikos",
            shortDescription:
              "halbtrocken",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Imiglykos",
            shortDescription:
              "halbsüß",
            price: "5,30€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Mavrodaphne",
            shortDescription:
              "süß",
            price: "6,70€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Weinschorle (0,4l)",
            shortDescription:
              "Klein (0,25l): 5,10€",
            price: "6,50€",
            allergens: "SU",
            allergensExplained:
              "Schwefeldioxid und Sulphide",
            veggie: "",
          },
        ],
      },
      // end of subCategory Offene Griechische Tafelweine rose
      {
        // subCategory object Flaschenweine rot
        id: uuid4(),
        name: "Flaschenweine",
        subtitle: "rot",
        items: [
          {
            id: uuid4(),
            number: "",
            name: "Biblia Chora (15,5%) (0,7l)",
            shortDescription:
              "Rebsorte: Syrah. Anbaugebiet: Makedonien Thrakien",
            price: "28,90€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
          {
            id: uuid4(),
            number: "",
            name: "Costa Lazaridi Amethystos (14%) (0,7l)",
            shortDescription:
              "Rebsorte: Cabernet Sauvignon. Anbaugebiet: Drama",
            price: "29,90€",
            allergens: "",
            allergensExplained:
              "",
            veggie: "",
          },
        ],
      },
      // end of subCategory Flaschenweine rot
    ],
    // end of category object Getränke
  },
  // end of array foodItems
];

export default foodItems;
