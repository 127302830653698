import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <div className="nav-container">
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to='/'>
          <img className="site-logo" src="../media/zum-tapferen-bayern-by-kosta.jpg" alt="" />
        </Link>
        <div className="navbar-nav ml-auto">
          <Link className='speisekarte-button' to='/speisekarte'>
            <span className="speisekarte-button-text">Speisekarte</span>
          </Link>
        </div>
      </nav>
    </div>

  );
}

export default Navigation;
