import React from "react";
import DividerFoodSubCategory from "./DividerFoodSubCategory";

function Subcategory(props) {
    return (
        <div className="row row-width subcategory-row" id={props.name}>
            <div className="col-12">
                <h3 className="subcategory-title">{props.name + ' '} 
                    {/* conditional rendering for subcategory subtitle */}
                    {props.subtitle !== "" ? 
                        <span className="subcategory-subtitle">
                            {'(' + props.subtitle + ')'}
                        </span>
                        : null }
                </h3>
            </div>
            <DividerFoodSubCategory /> 
        </div>
    )
}

export default Subcategory;