import React from 'react';

function ArrowToTop() {
    return (
        <div className="arrow-container">
            <a href="#food-menu-banner"><img className="to-top" src="../media/to-top.png" alt="" /></a>
        </div>
    )
}

export default ArrowToTop;