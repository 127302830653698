import React from 'react';

function DividerFoodSubCategory() {
    return (
        <div className="col-12 divider-col">
            <hr className="subcategory-divider" />
        </div> 
    )
}

export default DividerFoodSubCategory;