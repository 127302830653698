import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="footer">
      <footer>
        <div className="row row-width">
          <div className="col-12 footer-nav">
            <ul>
              <li className='footer-logo footer-item'>
                <Link to='/'>
                  <img className="site-logo" src="../media/zum-tapferen-bayern-by-kosta.jpg" alt="" />
                </Link>
              </li>
              <li className='footer-item'>
                <Link to='/datenschutz'>
                  <span>Datenschutz</span>
                </Link>
              </li>
              <li className='footer-item'>
                <Link to='/impressum'>
                  <span>Impressum</span>
                </Link>
              </li>
              <li className='footer-item'>
                <a href="#uc-corner-modal-show">Cookie-Einstellungen</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="author-credits">
          <div className="col-12">
            <a className="author" href="https://jannickvonroden.com" target="_blank" rel="noopener noreferrer">Website by Jannick von Roden</a>
          </div>
      </div>
    </section>
  );
}

export default Footer;