import React from "react";
import FoodMenuNav from "./MenuPage/FoodMenuNav";
import FoodMenu from "./MenuPage/FoodMenu";
import FoodMenuBanner from "./MenuPage/FoodMenuBanner";
import ArrowToTop from "./MenuPage/ArrowToTop";

function Speisekarte() {
    return (
        <div>
            <FoodMenuBanner />
            <FoodMenuNav />
            <FoodMenu />
            <ArrowToTop />
        </div>
    )
}

export default Speisekarte;