import React from 'react';

function FoodMenuNavItem(props) {
    return (
        <p className="nav-item-wrapper">
            <a className="food-menu-nav-item" href={`#${props.imgName}`}>{props.name}</a>
        </p>
        
    )
}

export default FoodMenuNavItem;