import React from 'react';
import FoodMenuNavItem from './FoodMenuNavItem';
import foodItems from '../../foodItems';
import $ from 'jquery';

function renderFoodMenuNavItem(category) {
    return (
        <FoodMenuNavItem
            key = {category.id}
            name = {category.name}
            imgName = {category.imgName}
        />
    );
}

function scrollRight() {
    var leftPos = $('#nav-items-col').scrollLeft();
    $('#nav-items-col').animate({scrollLeft: leftPos + 300}, 500);
}

function scrollLeft() {
    var leftPos = $('#nav-items-col').scrollLeft();
    $('#nav-items-col').animate({scrollLeft: leftPos - 300}, 500);
}

function FoodMenuNav() {
    return (
        <div className="row food-menu-nav">
            <div className="col-2 prev-col">
                <img id="prev" onClick={scrollLeft} src="../media/prev.png" alt="" />
            </div>
            <div className="col-8 nav-items-col" id="nav-items-col">
                {foodItems.map(renderFoodMenuNavItem)}
            </div>
            <div className="col-2 next-col">
                <img id="next" onClick={scrollRight} src="../media/next.png" alt="" />
            </div>            
        </div>
    )
}

export default FoodMenuNav;