import React from "react";
import foodItems from "../../foodItems";
import Category from "./Category";
import Subcategory from "./Subcategory";
import MenuItem from "./MenuItem";

function renderCategory(category) {
    return (
        <Category
            key = {category.id}
            name = {category.name}
            imgName = {category.imgName}
            nameColored = {category.nameColored}
            nameWhite = {category.nameWhite}
        />
    );
}

function renderSubcategory(subcategory) {
    return (
        <Subcategory
            key = {subcategory.id}
            name = {subcategory.name}
            subtitle = {subcategory.subtitle}
        />
    );
}

function renderMenuItem(menuItem) {
    return (
        <MenuItem
            key = {menuItem.id}
            id = {menuItem.id}
            number = {menuItem.number}
            name = {menuItem.name}
            price = {menuItem.price}
            shortDescription = {menuItem.shortDescription}
            veggie = {menuItem.veggie}
            allergens = {menuItem.allergens}
            allergensExplained = {menuItem.allergensExplained}
        />
    );
}

function generateFoodMenu() {
    let content = [];
    for (let categoryIndex in foodItems) {
        // push the category
        let category = foodItems[categoryIndex];
        content.push(renderCategory(category));
        for (let subcategoryIndex in category['subCategories']) {
            // push category's subcategories
            let subcategory = category['subCategories'][subcategoryIndex];
            content.push(renderSubcategory(subcategory));
            let itemsContent = [];
            for (let itemIndex in subcategory['items']) {
                // push subcategory's items
                let item = subcategory['items'][itemIndex]
                itemsContent.push(renderMenuItem(item));
            }
            let itemsWrapper = [];
            itemsWrapper.push(<div className="row row-width">{itemsContent}</div>);
            content.push(itemsWrapper);
        }
    }
    return(content);
}

function FoodMenu() 
{
    return (
        generateFoodMenu()
    )
}

export default FoodMenu;