import React from 'react';
import DividerFoodCategoryBanner from './DividerFoodCategoryBanner';

function FoodMenuBanner() {
    return (
        <div className="food-menu-banner" id="food-menu-banner">
            <h1>
                <span className="name-colored">
                        Speise
                </span>
                <span className="name-white">
                        karte
                </span>
            </h1>
            <DividerFoodCategoryBanner />
        </div>
    )
}

export default FoodMenuBanner;