import React from "react";
import DividerFoodCategoryBanner from "./DividerFoodCategoryBanner";

function imageSource(className) {
    return ("../../media/"+className+".jpg");
}

function Category(props) {
    return (
        <div id={props.imgName} className="row row-width parent-category-title-row">
            <div className="category-banner-img-container">
                <img className="category-banner-img" src={imageSource(props.imgName)} alt="" loading="lazy" />
                <div className="banner-overlay">
                    <div className="banner-overlay-text">
                    <h2 className="category-name">
                            <span className="name-colored">
                                {props.nameColored}
                            </span>
                            <span className="name-white">
                                {props.nameWhite}
                            </span>
                        </h2>
                        <DividerFoodCategoryBanner />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Category;