import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <section className="homepage-hero">
        <div className="row row-width">
          {/* Modal */}
          {/* <div class="modal" tabindex="-1" role="dialog" id="myModal">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Wir sind im Urlaub!</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p>Liebe Gäste, vom 14.08. - 10.09. befinden wir uns im Betriebs&shy;urlaub. Wir freuen uns, euch bald wieder in unserem Restaurant begrüßen zu dürfen!</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal">Schließen</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
          {/* <div className="col-2"></div> */}
          <div className="col-lg-5 col-md-6 col-sm-8 homepage-hero-stripe-column">
            <div className="homepage-hero-text-container">
              <p className="welcome">Herzlich willkommen im Gasthaus</p>
              <h1>Zum Tapferen Bayern by Kosta</h1>
              <p className="hero-description">
                Genießen Sie unsere herzhafte authentisch-griechische Küche.
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img src="../../media/phone.png" alt="" />
                    </td>
                    <td>
                      <a href="tel:09131 5304253">09131 5304253</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../media/address.png" alt="" />
                    </td>
                    <td>
                      <a
                        href="https://goo.gl/maps/fTxsxP8Xz9KVxLs69"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Nürnberger Str. 43, 91052 Erlangen
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../media/clock.png" alt="" />
                    </td>
                    <td>
                      <p className="opening-hours">
                        täglich von 11-15 und von 17-22 Uhr
                      </p>
                      <p className="opening-hours">Mittwoch: Ruhetag</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Link className="speisekarte-button" to="/speisekarte">
                <span className="speisekarte-button-text">Speisekarte</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-4 homepage-hero-empty-col"></div>
        </div>
      </section>
      <section className="about-us">
        <div className="row row-width">
          <div className="col-lg-6 about-us-text">
            <h2>Herein spaziert!</h2>
            <p>
              Wir verwöhnen Sie mit griechischen und mediterranen Spezialitäten.
              Zudem erwartet Sie eine Auswahl an edlen Tropfen aus den besten
              Weingütern.
            </p>
            <p>
              Bei unserer griechischen Gastfreundschaft ist es eine
              Selbstverständlichkeit, dass wir jedem Gast zur Begrüßung Ouzo auf
              Kosten des Hauses servieren!
            </p>
            <p>Kostas und sein Team wünschen Ihnen</p>
            <p>
              <strong>KALI OREXI</strong> <br />
              Guten Appetit
            </p>
          </div>
          <div className="col-lg-6">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    loading="lazy"
                    className="d-block w-100"
                    src="../../media/about-us-1.jpg"
                    alt="First slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    loading="lazy"
                    className="d-block w-100"
                    src="../../media/about-us-2.jpg"
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    loading="lazy"
                    className="d-block w-100"
                    src="../../media/about-us-3.jpg"
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    loading="lazy"
                    className="d-block w-100"
                    src="../../media/about-us-4.jpg"
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    loading="lazy"
                    className="d-block w-100"
                    src="../../media/about-us-5.jpg"
                    alt="Third slide"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
